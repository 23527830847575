import React from "react";
import 'assets/css/fa.css';
import "assets/scss/material-kit-react.scss";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/faces/duaa.jpg";

import styles from "assets/jss/material-kit-react/views/aboutMe.js";
import SEO from "../components/seo";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand="Duaa Rasho"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 50,
          color: "white"
        }}
        {...rest}
      />
      <SEO image={profile} pathname={"/aboutme/"}></SEO>
      <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container} style={{ paddingBottom: "100px" }}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Duaa Rasho</h3>
                    <h6>Autorin</h6>
                    <Button justIcon link className={classes.margin5} href="https://at.linkedin.com/in/دعاء-رشو-duaa-rasho-353201111">
                      <i className={"fab fa-linkedin"} />
                    </Button>
                    <Button href="https://www.instagram.com/dedaa_ra/?hl=en" justIcon link className={classes.margin5}>
                      <i className={"fab fa-instagram"} />
                    </Button>
                    <Button href="https://m.facebook.com/profile.php?id=100010897200948" justIcon link className={classes.margin5}>
                      <i className={"fab fa-facebook"} />
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                Born in Damascus in 1990. Her parents have kurd- ish roots.
                She attended elementary, middle and high school in Da- mascus.
                She has been working in sporadic and simple work since she was 12 years old.
                She has four sisters and two brothers.
                At the age of 18, she obtained a high school diploma, but this was not enough to enter the university and she
                had to en- ter a private university. She stopped studying for three years in order to work, so she could pay the annual
                tuition fees of the university. At the age of 21 she started studying English literature for a year, but soon the war broke
                out in Syria and she had to abandon university. In the meantime, she started working in civil society organizations and
                began teaching students the english language on a voluntary basis. She worked in teaching children for five years.
                In 2015, the place that she used to work in teaching had been destroyed by Mortar shells. After that, the decision to resort
                 to Europe was taken. She also worked on the wom- en rights in Syria and Austria. She has many articles on this subject
                 which have been published in arabic women's rights groups on social networking pages. She is now working to show the hidden
                facts be- hind the curtains on violence against women and oppression.{" "}
              </p>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
